import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaChartLine, FaBullhorn, FaUsers, FaEnvelope, FaStar } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaQuoteLeft } from 'react-icons/fa';

// Ícones de categorias
const getCategoryIcon = (categoryKey) => {
  switch (categoryKey) {
    case 'seo':
      return <FaChartLine className="text-indigo-600 h-5 w-5 mr-2" />;
    case 'sem':
      return <FaBullhorn className="text-indigo-600 h-5 w-5 mr-2" />;
    case 'crm':
      return <FaUsers className="text-indigo-600 h-5 w-5 mr-2" />;
    case 'emailMarketing':
      return <FaEnvelope className="text-indigo-600 h-5 w-5 mr-2" />;
    default:
      return null;
  }
};

const StarRating = ({ rating }) => (
  <div className="flex">
    {Array(5).fill().map((_, i) => (
      <FaStar key={i} className={`h-5 w-5 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`} />
    ))}
  </div>
);

const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    onClick={onClick}
    style={{
      ...style,
      display: 'block',
      color: '#000000', // Define a cor da seta para preto
      fill: '#000000', // Para SVGs, define o preenchimento como preto
    }}
  />
);

const reviews = [
  { name: 'João Costa', location: 'Lisboa', reviewKey: 'reviews.review1', rating: 5, categoryKey: 'seo' },
  { name: 'Ana Martins', location: 'Porto', reviewKey: 'reviews.review2', rating: 4, categoryKey: 'sem' },
  { name: 'Pedro Almeida', location: 'Coimbra', reviewKey: 'reviews.review3', rating: 5, categoryKey: 'crm' },
  { name: 'Carla Sousa', location: 'Faro', reviewKey: 'reviews.review4', rating: 4, categoryKey: 'emailMarketing' },
];

const CustomerReviews = () => {
  const { t } = useTranslation();

  // Configuração do carrossel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />, // Setas personalizadas
    prevArrow: <CustomArrow />,
    customPaging: () => (
      <div
        style={{
          width: '22px',
          height: '22px',
          borderRadius: '50%',
          background: '#ddd',
        }}
      />
    ),
    dotsClass: 'slick-dots custom-dots',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (

    
    <div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
      <h2 className="text-center text-3xl font-extrabold text-gray-900 font-ansone">
  <span className="inline-flex items-center">
    <FaQuoteLeft style={{ color: "gray", marginRight: "0.5rem" }} />
    {t('whatOurCustomersSay')}
  </span>
</h2>

        <Slider {...settings} className="mt-10">
          {reviews.map((review, index) => (
            <div key={index} className="p-6">
              <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300" style={{ height: '400px' }}>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    {getCategoryIcon(review.categoryKey)}
                    <span className="text-sm font-bold uppercase text-indigo-600">
                      {t(review.categoryKey)}
                    </span>
                  </div>
                  <StarRating rating={review.rating} />
                </div>
                <blockquote className="mt-4 text-lg font-medium text-gray-600" style={{ fontStyle: 'italic', position: 'relative' }}>
                  <span style={{
                    fontSize: '40px',
                    position: 'absolute',
                    left: '-10px',
                    top: '-10px',
                    color: '#000'
                  }}>“</span>
                  <p style={{ display: 'inline' }}>
                    <strong>{t(review.reviewKey).split('. ')[0]}.</strong> {t(review.reviewKey).split('. ').slice(1).join('. ')}
                  </p>
                  <span style={{
                    fontSize: '40px',
                    position: 'absolute',
                    right: '-10px',
                    bottom: '-10px',
                    color: '#000'
                  }}>”</span>
                </blockquote>
                <div className="mt-4">
                  <p className="text-base font-semibold text-gray-900">{review.name}</p>
                  <p className="text-sm text-gray-500">{review.location}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>





  );
};

export default CustomerReviews;
