import React from 'react';
import { FaHandshake } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';
import { FaBrain } from 'react-icons/fa';

import { useTranslation } from 'react-i18next'; // Importa o hook de tradução
import logofooter from '../src/assets/logoquickflowai.png';
import { FaShieldAlt, FaPercent, FaStethoscope, FaPhoneAlt } from 'react-icons/fa'; // Ícones
import tiktokLogo from '../src/assets/TikTok-Marketing-Partner-1.png';
import metaLogo from '../src/assets/Group-39465.svg';
import googleLogo from '../src/assets/Group-39464.svg';
import { TiArrowUpOutline } from "react-icons/ti";
import funnel from '../src/assets/funnel.png';
import funnelmobile from '../src/assets/funnelmobile.png';
import openaiLogo from '../src/assets/openai.png';
import crm from '../src/assets/crm.png';
import crmmobile from '../src/assets/crmmobile.png';
import { FaTrophy } from 'react-icons/fa';

function Footer() {
  const { t } = useTranslation(); // Hook de tradução

  return (
    <footer>
  
      <div className="bg-gray-200 pt-10 pb-10 px-6">
  
      <h2 className="text-4xl font-extrabold mb-6 p-[40px] relative text-center">
  <div className="inline-flex justify-center">
    <FaHandshake style={{ color: "gold" }} />
  </div>
  <div className="pt-2">
    {t('ourPartnerships')}
  </div>
</h2>


  <div className="flex flex-wrap md:flex-nowrap justify-center gap-8">
    <div className="w-full md:w-1/4 text-center">
      <img src={tiktokLogo} alt="TikTok Marketing Partner" className="w-40 mx-auto" />
    </div>
    <div className="w-full md:w-1/4 text-center">
      <img src={openaiLogo} alt="Open Ai" className="w-40 mx-auto" />
    </div>
    <div className="w-full md:w-1/4 text-center">
      <img src={googleLogo} alt="Google Marketing Partner" className="w-40 mx-auto" />
    </div>
    <div className="w-full md:w-1/4 text-center">
      <img src={metaLogo} alt="Meta Marketing Partner" className="w-40 mx-auto" />
    </div>
  </div>
<br></br><br></br>
  <div style={{ width: '100%',  textAlign: 'center', paddingBottom: '30px' }}>


 

</div>


    
</div>







      {/* Achievements */}
      <div
        className="bg-black text-white  text-center  "
   
      >

<h2 className="text-4xl font-extrabold mb-6 p-[40px] relative">
  <div className="inline-flex justify-center">
    <FaTrophy style={{ color: "gold" }} />
  </div>
  <div className="pt-2">
    {t('achievementsSection.title')}
  </div>
</h2>

<div className="bg-black text-white p-8 flex flex-col items-center">
  
      <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8">
        <div className="text-center">
          <p className="text-lg mb-2">{t('achievementsSection.growMore')}</p>
          <p className="text-5xl font-bold">{t('achievementsSection.by')}  <span className="text-white">67%</span></p>
        </div>
        <div className="text-center">
          <p className="text-lg mb-2">{t('achievementsSection.spendLess')}</p>
          <p className="text-5xl font-bold">{t('achievementsSection.by')} <span className="text-white">32%</span></p>
        </div>
        <div className="text-center">
          <p className="text-lg mb-2">{t('achievementsSection.maximiseROI')}</p>
          <p className="text-5xl font-bold"><span className="text-white">100%</span></p>
        </div>
      </div>
    </div>

        <div className="relative z-10 max-w-3xl mx-auto mt-8">
          <p className="text-xl mb-8 leading-relaxed tracking-wide">
            {t('achievementsDescription')}
          </p>

          <p className="text-2xl font-semibold border-l-4 border-yellow-500 pl-4 py-4 bg-gray-800 bg-opacity-50 mb-6">
            {t('makePeopleSmile')}
          </p>
        </div>
 

      <div>
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
     
      }}
    >
      <div
        style={{
          padding: '20px',
       
          textAlign: 'center'
        }}
      >
  {/* Imagem para Desktop */}
  <img 
    src={funnel} 
    alt="Top Advantages" 
    style={{ maxWidth: '100%' }} 
    className="desktop-image" 
  />

  {/* Imagem para Mobile */}
  <img 
    src={funnelmobile} 
    alt="Top Advantages" 
    style={{ maxWidth: '100%' }} 
    className="mobile-image" 
  />
</div></div>

<style jsx>{`
  /* Por padrão, a imagem de mobile fica oculta */
  .mobile-image {
    display: none;
  }

  /* Exibir a imagem mobile em telas pequenas */
  @media (max-width: 768px) {
    .desktop-image {
      display: none;  /* Oculta a imagem desktop em mobile */
    }
    .mobile-image {
      display: block; /* Exibe a imagem mobile em telas pequenas */
    }
  }
`}</style>

      </div>

        
      <div style={{ textAlign: 'center' }}>
      <div
  className="bg-[#ffffff] w-full text-black text-center p-10 text-2xl font-bold cursor-pointer transition-all duration-300 hover:bg-[#FF0064]  hover:text-black"
  onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
>
{t('ReadytoGrowth')}
</div>
</div>
<div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
     
      }}
    >
      <div
        style={{
          padding: '20px',
       
          textAlign: 'center'
        }}
      >
  {/* Imagem para Desktop */}
  <img 
    src={crm} 
    alt="CRM" 
    style={{ maxWidth: '100%' }} 
    className="desktop-image" 
  />

  {/* Imagem para Mobile */}
  <img 
  src={crm} 
    alt="crmmobile" 
    style={{ maxWidth: '100%' }} 
    className="mobile-image" 
  />
</div></div>

 
 


<h2 className="text-4xl font-extrabold mb-6 p-[40px] relative">
  <div className="inline-flex justify-center">
    <FaBrain  style={{ color: "gold" }} />
  </div>
  <div className="pt-2">
    {t('HowWeThink')}
  </div>
</h2>


<div className="flex flex-col md:flex-row justify-center items-center gap-4 p-4 pb-10 w-full">
      {/* Card 1 */}
      <div className="border-2 border-white rounded-lg p-6 max-w-md text-left">
        <h3 className="text-lg font-bold mb-2 text-white">
          {t('extraMileTitle')}{' '}
          <span className="text-white">{t('marathonSubtitle')}</span>
        </h3>
        <p className="text-white text-base">
          {t('extraMileDescription')}
        </p>
      </div>

      {/* Card 2 */}
      <div className="border-2 border-white rounded-lg p-6 max-w-md text-left">
        <h3 className="text-lg font-bold mb-2 text-white">
          {t('timeEssenceTitle')}{' '}
          <span className="text-white">{t('noWasteSubtitle')}</span>
        </h3>
        <p className="text-white text-base">
          {t('timeEssenceDescription')}
        </p>
      </div>

      {/* Card 3 */}
      <div className="border-2 border-white rounded-lg p-6 max-w-md text-left">
        <h3 className="text-lg font-bold mb-2 text-white">
          {t('notBoringTitle')}{' '}
          <span className="text-white">{t('joySubtitle')}</span>
        </h3>
        <p className="text-white text-base">
          {t('notBoringDescription')}
        </p>
      </div>
    </div>






      </div>
 


      <div className="bg-black text-white px-8 py-12 flex justify-center">
      <div className="flex flex-col md:flex-row items-center md:items-start justify-center max-w-[1200px] w-[90%] mx-auto">
        <div className="text-5xl md:text-6xl font-bold leading-tight uppercase text-center">
          <p>{t('footerdontWaste')}</p>
          <button
            className="mt-4 px-6 py-2 bg-white text-black font-bold rounded hover:bg-gray-200 transition-colors"
            onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
          >
            {t('footercallUs')}
          </button>
        </div>
      </div>
    </div>





      <section className="footer-info">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logofooter} alt="Logo Fidelidade" />
          </div>

          <div style={{ textAlign: 'center' }} className="block lg:hidden">
  <button
    type="submit"
    className="inline-flex items-center justify-center whitespace-nowrap px-4 py-2 border-4 border-[#FF0064] text-[#ffffff] bg-[#000000] rounded-md font-bold hover:bg-[#FF0064] hover:text-white transition-colors duration-300"
    onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
  >
    <TiArrowUpOutline className="mr-2" />
    {t('dontWaste')}
  </button>
</div>


          <div className="footer-text" style={{ textAlign: 'left' }}>
  <p>
    <b>Head Office</b> <br />
    Avenida Coronel Eduardo Galhardo, 1170-105 Lisbon, Lisbon, Portugal
    <br />  <br />
    <b>Satellite office</b> <br />
    Departamentos Puerto Paraíso (Dpto 11)
    <br />
    Carretera Costera s/n, A. Cárdenas Tlaxcala y fte a PFP 
    <br />
    Colonia Zicatela CP 70934, Mexico
    <br />  <br />
    350 5th Avenue, Suite 1200, New York, NY 10118, USA



    <br />  <br />
    <b>{t('callUs')}:</b> +351 937 458 548
    <br />
    <b>{t('startProject')}:</b> <a href="mailto:waiting@quickflowai.com">waiting@quickflowai.com</a>
    <br>
    </br>    <br>
    </br>
    <a href="terms.html">Terms and Conditions</a><br></br>
    <a href="terms.html">Cookie Policy</a>
  </p>
</div>

<div></div>

        </div>
      </section>
    </footer>






  );
}

export default Footer;
