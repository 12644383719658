import React, { useState, useEffect } from 'react';
import { FaArrowRight, FaGlobe, FaRobot, FaBolt, FaChartLine } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importa o hook de tradução
import topadvanteges from '../src/assets/topadvanteges.png';
import topadvantegesmobile from '../src/assets/topadvantegesmobile.png';
import strategy from '../src/assets/strategy.png';
import strategymobile from '../src/assets/strategymobile.png';
import paidmedia from '../src/assets/paidmedia.png';

import './BenefitsGrid.css';

const RotatingText = () => {
  const { t } = useTranslation(); // Hook de tradução
  const [index, setIndex] = useState(0);
  const texts = [t('paidMedia'), t('creative'), t('dataGrowth')]; // Textos rotativos com tradução

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [texts.length]);

  return (

    
    <div className="rotating-container">
      <h1 className="main-text">
        {t('weDo')} <span className="rotating-text">{texts[index]}</span>
        <br />
        <span className="flex items-center justify-center" style={{ fontSize: "14px" }}>
          <FaGlobe className="mr-2" /> {t('worldwide')}
        </span>
      </h1>
    </div>
  );
};

const BenefitsGrid = () => {
  const { t } = useTranslation(); // Hook de tradução
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 200 && rect.bottom >= 200) {
          setActiveSection(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const Workflow = () => {
    const workflowContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '20px',
      fontFamily: 'Arial, sans-serif',
      color: '#fff',
      backgroundColor: '#000',
      padding: '20px'
    };
  
    const levelStyle = {
      display: 'flex',
      gap: '40px',
      alignItems: 'center'
    };
  
    const itemStyle = {
      position: 'relative',
      padding: '10px 20px',
      backgroundColor: '#333',
      borderRadius: '5px'
    };
  
    const arrowStyle = {
      content: '→',
      position: 'absolute',
      right: '-30px',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '20px',
      color: '#fff'
    } };

  return (
    <>



 

      <div style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '40px'}}>
     
     
      <div className="p-10">


        
  {/* Imagem para Desktop */}
  <img 
    src={topadvanteges} 
    alt="Top Advantages" 
    style={{ maxWidth: '100%' }} 
    className="desktop-image" 
  />

  {/* Imagem para Mobile */}
  <img 
    src={topadvantegesmobile} 
    alt="Top Advantages" 
    style={{ maxWidth: '100%' }} 
    className="mobile-image" 
  />
</div>

<style jsx>{`
  /* Por padrão, a imagem de mobile fica oculta */
  .mobile-image {
    display: none;
  }

  /* Exibir a imagem mobile em telas pequenas */
  @media (max-width: 768px) {
    .desktop-image {
      display: none;  /* Oculta a imagem desktop em mobile */
    }
    .mobile-image {
      display: block; /* Exibe a imagem mobile em telas pequenas */
    }
  }
`}</style>
    </div>
     

      {/* Section 1 */}
      <section className="c-big-list list-2 u-comp-def--1 -flex -index--5 after:big-list before:big-list" style={{ backgroundColor: 'black' }} id="comp6">
      <div className="c_inner u-wrapper u-wrapper--1 u-wrapper--pad" data-inview-child="">
          <div className="list_wrapper">
            <div className="left_column">
              <div className="heading">
                <h2 className="flex items-center">
                  <FaRobot className="mr-2 accent-dot" /> {t('paidOrganicMedia')}
                  <span className="accent-dot">.</span>
                </h2>

 
              </div>
            </div>
            <div className="right_column">
              <div className="text">
                <p>{t('mediaDescription')}</p>
              </div>
              <div className="list">
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#2CC157' }} />
                  <p>{t('paidSocial')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#2CC157' }} />
                  <p>{t('paidSearch')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#2CC157' }} />
                  <p>{t('seo')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <section className="c-big-list list-2 u-comp-def--1 -flex -index--5 after:big-list before:big-list" style={{ backgroundColor: 'black' }} id="comp6">
      <div className="c_inner u-wrapper u-wrapper--1 u-wrapper--pad" data-inview-child="">
          <div className="list_wrapper">
            <div className="left_column">
              <div className="heading">
                <h2 className="flex items-center">
                  <FaBolt className="mr-2 accent-dot-pink" /> {t('creative')}
                  <span className="accent-dot-pink">.</span>
                </h2>
              </div>
            </div>
            <div className="right_column">
              <div className="text">
                <p>{t('creativeDescription')}</p>
              </div>
              <div className="list">
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('creativeStrategy')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('adCreative')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('contentShoots')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#EDB9FF' }} />
                  <p>{t('ugc')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <section className="c-big-list list-3 u-comp-def--1 -flex -index--5 after:big-list before:big-list" style={{ backgroundColor: 'black' }} id="comp6">
      <div className="c_inner u-wrapper u-wrapper--1 u-wrapper--pad" data-inview-child="">
          <div className="list_wrapper">
            <div className="left_column">
              <div className="heading">
                <h2 className="flex items-center">
                  <FaChartLine className="mr-2 text-black accent-dot-blue" /> {t('dataScience')}
                  <span className="accent-dot-blue">.</span>
                </h2>
              </div>
            </div>
            <div className="right_column">
              <div className="text">
                <p>{t('dataScienceDescription')}</p>
              </div>
              <div className="list">
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#36C5F0' }} />
                  <p>{t('attributionModelling')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#36C5F0' }} />
                  <p>{t('lifetimeValueAnalysis')}</p>
                </div>
                <div className="point">
                  <FaArrowRight style={{ fontSize: '18px', marginRight: '8px', color: '#36C5F0' }} />
                  <p>{t('barkInsights')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '40px'}}>
      <div>
  {/* Imagem para Desktop */}
  <img 
    src={strategy} 
    alt="Top Advantages" 
    style={{ maxWidth: '100%' }} 
    className="desktop-image" 
  />

  {/* Imagem para Mobile */}
  <img 
    src={strategymobile} 
    alt="Top Advantages" 
    style={{ maxWidth: '100%' }} 
    className="mobile-image" 
  />
</div>

<style jsx>{`
  /* Por padrão, a imagem de mobile fica oculta */
  .mobile-image {
    display: none;
  }

  /* Exibir a imagem mobile em telas pequenas */
  @media (max-width: 768px) {
    .desktop-image {
      display: none;  /* Oculta a imagem desktop em mobile */
    }
    .mobile-image {
      display: block; /* Exibe a imagem mobile em telas pequenas */
    }
  }
`}</style>
    </div>

    </>



  );
};

export default BenefitsGrid;
