// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Traduções
const resources = {
  en: {
    translation: {
      ReadytoGrowth: "Ready to Growth?",
      HowWeThink: "How do we think?",

      davidTitle: "David & Goliath",
      davidDescription: "We believe there is a David in everyone ready to defeat giants. We are the company that values all projects, regardless of their size."
      ,
      mixingArt: "MIXING ART &",
      scienceDrive: "SCIENCE TO DRIVE",
      performance: "PERFORMANCE",
      introDescription: "We are a data driven full funnel marketing agency that combines cutting edge performance techniques and best in class creative production to drive real results."
,      
 
        extraMileTitle: "Some Go The Extra Mile.",
        marathonSubtitle: "We'll Run A Marathon.",
        extraMileDescription: "Good enough isn’t good enough for us. We pride ourselves with creating exceptional digital work that stands out from the crowd, no matter how far we need to go to do it, with unparalleled attention-to-detail.",
        
        timeEssenceTitle: "Time Is Of The Essence.",
        noWasteSubtitle: "And We Won’t Waste Yours.",
        timeEssenceDescription: "Our team are trained in agile project management, and we work round-the-clock to deliver on time. No project has too tight a turnaround for our agency, we’re ready for the challenge.",
        
        notBoringTitle: "It Doesn’t Have To Be Boring.",
        joySubtitle: "We Bring Back The Joy.",
        notBoringDescription: "You aren’t boring - and neither are we. Our goal is to make working with us an enjoyable and painless journey - hopefully one that you’ll want to make again (and again).",
   

      OrderForm: "We need just a bit of information in this form to ensure we connect you with our best expert for your needs."
,footercallUs :"Get Started",
footerdontWaste : "What are you waiting for?",
marketingStrategy: "Take your SaaS marketing strategy to the next level",
growMore: "Grow more",
growMoreDescription: "Optimise your sales/marketing matrix for business growth without limitations.",
spendLess: "Spend less",
spendLessDescription: "Slash CAC and close value gaps to improve your organisation's cost-efficiency.",
maximiseROI: "Maximise ROI",
maximiseROIDescription: "MRR, ARR, CLV, Cycle Management. Start improving your ROI today.",
connectDots: "Connect the dots",
connectDotsDescription: "Align automation, AI, and strategy for powerful SaaS marketing.",
empowerGTM: "Empower GTM",
empowerGTMDescription: "Maximise your go-to-market strategy and secure next-round funding.",
allOfThese: "All of these",
allOfTheseDescription: "We’ll assist you in overcoming your biggest challenges and achieving your most ambitious metrics."
,
      whatOurCustomersSay: "What our customers say"
      ,
            location: "Location",
            category: "Category",
            starRating: "Star Rating",
            review: "Review",
            seo: "SEO",
            sem: "SEM",
            crm: "CRM",
            emailMarketing: "Email Marketing",
            reviews: {
              review1: "QuickflowAI completely transformed our SEO strategy. Before, we were buried on the last pages of Google, but with the help of the team, we are now at the top of searches. Our visibility has never been higher!",
              review2: "We invested in SEM with QuickflowAI and the results were fantastic! The campaigns were optimized with precision, and the number of qualified leads increased exponentially. Our conversion rate more than doubled!",
              review3: "QuickflowAI implemented a robust CRM system that revolutionized our customer service. Now we have valuable data about our customers, and we can offer a personalized experience that greatly improves retention.",
              review4: "Our email marketing strategy was stagnant, but the QuickflowAI team managed to revitalize it. Now our campaigns have a much higher open and click rate, which translates into more sales and greater customer engagement."
            },
      welcome: "The traditional digital model",
      welcome2: "is broken.",
      nearLocation: "I think you might be near the outskirts of",
      nearLocation1: "Start making a difference there.",
      funnel: "Site created with love and AI in record time (with a coffee break)",
      formtile: "Redefine Your Rules with AI",
      step1Message: "What’s your name?",
      step2Message: "Complete the form",
      RequestaQuote: "Request a Quote",
      step3Message: "describe what you need.",
      nextStep: "NEXT STEP",
      requestQuote: "REQUEST QUOTE",
      thankYouMessage: "Thank you for requesting a quote.",
      aiElevate: "Let AI experts elevate your strategy.",
      aiElevate1: "Connecting businesses to their audiences with data-driven creativity. Growth and results—powered by numbers.",
      phoneNumber: "Phone Number",
      observations: "Briefly describe the project and its objectives",
      fullName: "Full Name",
      loadingMessage: "I am waiting to hear more...",
      successMessage: "Our team will get in touch with you soon with our best offer.",
      letsTalk: "Let's Talk – No Time to Waste!",
      ourPartnerships: "Our Partnerships",
      achievements: "What We've Achieved",
      achievementsDescription: "With a team ranging from 1 to 20 years of experience, we’ve achieved many, many millions. We could boast about numbers here, but we prefer to let our work speak for itself. Our clients? Always confidential.",
      makePeopleSmile: "AI in all our marketing funnels.",
      workWithUs: "Work With Our Amazing Team",
      dontWaste: "Don't waste a moment",
      callUs: "Give us a Call",
      startProject: "Begin writing your next project",
      weDo: "With AI",
      worldwide: "Worldwide",
      paidMedia: "Paid Media",
      creative: "Creative",
      dataGrowth: "Data.Growth.",
      whatWeDo: "What We Do",
      withYou: "With You",
      partnersBenefit: "Our partners benefit from a disruptive, AI-powered team of media buyers, creatives, and data scientists who work seamlessly to transform both your paid media and organic search. By breaking conventional boundaries, we push your growth targets beyond expectations and redefine what's possible across every digital channel.",
      
      // Section 1
      paidOrganicMedia: "Paid & Organic Media",
      mediaDescription: "Implement a full-funnel strategy across Paid Social and Paid Search to build awareness and drive revenue.",
      paidSocial: "Paid Social",
      paidSearch: "Paid Search",
      seo: "SEO",
      
      // Section 2
      creativeDescription: "Create high-performing ads that build brand and power your performance.",
      creativeStrategy: "Creative Strategy",
      adCreative: "Ad Creative",
      contentShoots: "Content Shoots",
      ugc: "UGC",
      
      // Section 3
      dataScience: "Data Science",
      dataScienceDescription: "Unlock new avenues for growth with tech-enabled insights that solve your blind spots.",
      attributionModelling: "Attribution Modelling",
      lifetimeValueAnalysis: "Lifetime Value Analysis",
      barkInsights: "Bark.Insights",
      NextStep: "Next Step",
      didYouKnow: "Did you know:",
      notificationText: "We craft brands that people buy into and campaigns that make them take action. We make people care about your brand using the power of human connection.",
      readyToFeel: "Are you ready to make people feel something?",
      yes: "Yes"
    }
  },
  pt: {
    translation: {footercallUs: "Comece já",
      ReadytoGrowth: "Pronto para Crescer?",
      HowWeThink: "Como pensamos?",
      davidTitle: "David & Golias",
      davidDescription: "Acreditamos que em cada pessoa existe um David pronto para derrotar Gigantes. Somos a empresa que valoriza todos os projectos, independente do seu tamanho."
  ,

      footerdontWaste: "Do que está à espera?",
      extraMileTitle: "Alguns Dão O Extra.",
      marathonSubtitle: "Nós Corremos Uma Maratona.",
      extraMileDescription: "Para nós, o suficiente não é suficiente. Orgulhamo-nos de criar trabalho digital excepcional que se destaca da multidão, independentemente da distância a que precisamos ir, com uma atenção ao detalhe inigualável.",
      mixingArt: "MISTURAMOS ARTE",
scienceDrive: "& CIÊNCIA PARA IMPULSIONAR",
performance: "DESEMPENHO",
introDescription: "Somos uma agência de marketing full funnel orientada por dados, que combina técnicas de performance inovadoras e produção criativa de excelência para alcançar resultados reais."
,
      timeEssenceTitle: "O Tempo É Essencial.",
      noWasteSubtitle: "E Não Vamos Desperdiçar O Seu.",
      timeEssenceDescription: "A nossa equipa é formada em gestão ágil de projetos e trabalha incessantemente para entregar a tempo. Nenhum projeto tem um prazo demasiado apertado para a nossa agência; estamos prontos para o desafio.",
      
      notBoringTitle: "Não Tem De Ser Monótono.",
      joySubtitle: "Trazemos De Volta A Alegria.",
      notBoringDescription: "O nosso objetivo é tornar a colaboração connosco numa experiência agradável e sem dores, que esperamos que queira repetir (vezes sem conta).",
      

      achievementsSection: {
        title: "O que podemos alcançar juntos?",
        by: "em",
        growMore: "Aumentamos a geração de leads",
        spendLess: "Melhoramos o  engagement",
        maximiseROI: "Optimizamos as conversões em mais de"
      },


      marketingStrategy: "Transforme a  sua estratégia de marketing e assegure a liderança no seu mercado",
      growMore: "Cresça mais",
      growMoreDescription: "Otimize a sua matriz de vendas/marketing para o crescimento empresarial sem limitações.",
      spendLess: "Gaste menos",
      spendLessDescription: "Melhore a eficiência de custos da sua organização ao eliminar lacunas de valor e maximizar os resultados.",
      maximiseROI: "Maximize o ROI",
      maximiseROIDescription: "Invista no aumento do seu retorno e concentre-se na gestão e no valor vitalício dos seus clientes.",
      connectDots: "Conecte os pontos",
      connectDotsDescription: "Alinhe automação, IA e estratégia para um marketing SaaS poderoso.",
      empowerGTM: "Capacite o GTM",
      empowerGTMDescription: "Maximize a sua estratégia de go-to-market e garanta o financiamento da próxima ronda.",
      allOfThese: "Tudo isto",
      allOfTheseDescription: "Ajudamo-lo a superar os seus maiores desafios e a alcançar as suas métricas mais ambiciosas.",
      
 
      OrderForm: "Precisamos apenas de algumas informações neste formulário para garantir que o conectaremos ao nosso melhor especialista para suas necessidades.",
      whatOurCustomersSay:"O que dizem os nossos clientes",
      name: "Nome",
      location: "Localização",
      category: "Categoria",
      starRating: "Avaliação de Estrelas",
      review: "Avaliação",
      seo: "SEO",
      sem: "SEM",
      crm: "CRM",
      emailMarketing: "Email Marketing",
      reviews: {
        review1: "A QuickflowAI transformou completamente a nossa estratégia de SEO. Antes, estávamos enterrados nas últimas páginas do Google, mas com a ajuda da equipa, agora estamos no topo das pesquisas. A nossa visibilidade nunca foi tão elevada!",
        review2: "Investimos em SEM com a QuickflowAI e os resultados foram fantásticos! As campanhas foram optimizadas com precisão, e o número de leads qualificados aumentou exponencialmente. A nossa taxa de conversão mais do que duplicou!",
        review3: "A QuickflowAI implementou um sistema CRM robusto que revolucionou o nosso atendimento ao cliente. Agora temos dados valiosos sobre os nossos clientes e conseguimos oferecer uma experiência personalizada que melhora ainda mais a fidelização.",
        review4: "A nossa estratégia de email marketing estava estagnada, mas a equipa da QuickflowAI conseguiu revitalizá-la. Agora, as nossas campanhas têm uma taxa de abertura e cliques muito superior, o que se traduz em mais vendas e maior envolvimento com os clientes."
      },
      didYouKnow: "Sabia que:",
      notificationText: "Criamos marcas que as pessoas abraçam e campanhas que as fazem agir. Fazemos com que as pessoas se importem com a sua marca usando o poder da conexão humana.",
      readyToFeel: "Está pronto para fazer as pessoas sentirem algo?",
      yes: "Sim",
      step3Message: "descreva o que precisa.",
      welcome: "O modelo digital convencional",
      welcome2: "já não funciona.",
      RequestaQuote: "Pedir Proposta",
      NextStep: "Próximo Passo",
      nearLocation: "Penso que estás nas proximidades de:",
      nearLocation1: "Este é o local perfeito para começares a fazer a diferença!",
      funnel: "Site criado com amor e IA em tempo recorde (com pausa para café)",
      formtile: "Redefine as Regras com IA",
      step1Message: "Qual é o seu nome?",
      step2Message: "Complete o formulário",
      nextStep: "PRÓXIMO PASSO",
      requestQuote: "SOLICITAR ORÇAMENTO",
      thankYouMessage: "Obrigado por solicitar um orçamento.",
      aiElevate: "Deixe os especialistas em IA elevar a sua estratégia.",
      aiElevate1: "Conectamos empresas aos seus públicos com criatividade orientada por dados. Crescimento e resultados—impulsionados por números.",
      phoneNumber: "Número de Telefone",
      observations: "Descreva brevemente o projeto e os seus objetivos",
      fullName: "Nome Completo",
      loadingMessage: "Estou a aguardar para saber mais...",
      successMessage: "A nossa equipa entrará em contacto consigo em breve com a nossa melhor oferta.",
      letsTalk: "Vamos Conversar – Não Há Tempo a Perder!",
      ourPartnerships: "As Nossas Parcerias",
      achievements: "O Que Alcançámos",
      achievementsDescription: "Com metodologias impulsionadas por IA, alcançamos resultados diferentes e aceleramos o progresso em todas as etapas.",
      makePeopleSmile: "IA em todos os nossos funis de marketing.",
      workWithUs: "Trabalhe Com a Nossa Equipa Incrível",
      dontWaste: "Não perca tempo",
      callUs: "Ligue-nos",
      startProject: "Comece a planear o seu próximo projeto",
      weDo: "COM AI",
      worldwide: "Mundialmente",
      paidMedia: "Publicidade Paga",
      creative: "Criatividade",
      dataGrowth: "Dados e Crescimento",
      whatWeDo: "O Que Fazemos",
      withYou: "Consigo",
      partnersBenefit: "Os nossos parceiros beneficiam de uma equipa inovadora, impulsionada por IA, composta por especialistas em publicidade, criativos e cientistas de dados que trabalham em conjunto para transformar tanto a sua publicidade paga quanto a sua pesquisa orgânica. Ao quebrar os limites convencionais, superamos as metas de crescimento e redefinimos o que é possível em todos os canais digitais.",
      
      // Secção 1
      paidOrganicMedia: "Publicidade Paga & Orgânica",
      mediaDescription: "Implemente uma estratégia de funil completo no Paid Social e na Pesquisa Paga para aumentar o reconhecimento e gerar receita.",
      paidSocial: "Publicidade em Redes Sociais",
      paidSearch: "Pesquisa Paga",
      seo: "SEO",
      
      // Secção 2
      creativeDescription: "Crie anúncios de alto desempenho que constroem a marca e impulsionam o seu desempenho.",
      creativeStrategy: "Estratégia Criativa",
      adCreative: "Publicidade Criativa",
      contentShoots: "Produção de Conteúdo",
      ugc: "Conteúdo Gerado pelo Utilizador (UGC)",
      
      // Secção 3
      dataScience: "Ciência de Dados",
      dataScienceDescription: "Descubra novas oportunidades de crescimento com insights habilitados por tecnologia que resolvem os seus pontos cegos.",
      attributionModelling: "Modelagem de Atribuição",
      lifetimeValueAnalysis: "Análise de Valor ao Longo da Vida",
      barkInsights: "Bark.Insights"
    },
  },
  es: {
    translation: {
      HowWeThink: "¿Cómo pensamos?",
      davidTitle: "David y Goliat",
      davidDescription: "Creemos que en cada persona hay un David listo para derrotar gigantes. Somos la empresa que valora todos los proyectos, sin importar su tamaño."
,      
      ReadytoGrowth: "¿Listo para Crecer?",
      achievementsSection: {
        title: "¿Qué podemos lograr juntos?",
        by: "en",
        growMore: "Aumentamos la generación de leads",
        spendLess: "Mejoramos el engagement",
        maximiseROI: "Optimizamos las conversiones en más de"
      },
      
      mixingArt: "MEZCLANDO ARTE &",
scienceDrive: "CIENCIA PARA IMPULSAR",
performance: "RENDIMIENTO",
introDescription: "Somos una agencia de marketing full funnel orientada por datos que combina técnicas de rendimiento de vanguardia y producción creativa de primer nivel para obtener resultados reales."
,
      extraMileTitle: "Algunos Dan Más De Lo Esperado.",
marathonSubtitle: "Nosotros Corremos Una Maratón.",
extraMileDescription: "Para nosotros, lo suficientemente bueno no es suficiente. Nos enorgullecemos de crear trabajos digitales excepcionales que destacan, sin importar hasta dónde debamos llegar, con una atención al detalle inigualable.",

timeEssenceTitle: "El Tiempo Es Esencial.",
noWasteSubtitle: "Y No Vamos A Desperdiciar El Suyo.",
timeEssenceDescription: "Nuestro equipo está capacitado en gestión ágil de proyectos y trabajamos sin parar para cumplir con los plazos. Ningún proyecto es demasiado ajustado para nuestra agencia; estamos listos para el desafío.",

notBoringTitle: "No Tiene Que Ser Aburrido.",
joySubtitle: "Devolvemos La Alegría.",
notBoringDescription: "Usted no es aburrido - y nosotros tampoco. Nuestro objetivo es hacer que trabajar con nosotros sea una experiencia agradable y sin dolor, que ojalá quiera repetir una y otra vez.",


      whatOurCustomersSay: "Lo que dicen nuestros clientes"
      ,footercallUs: "Empieza ahora",
      footerdontWaste: "¿Qué estás esperando?",
      
      marketingStrategy: "Lleve su estrategia de marketing SaaS al siguiente nivel",
growMore: "Crezca más",
growMoreDescription: "Optimice su matriz de ventas/marketing para un crecimiento empresarial sin límites.",
spendLess: "Gaste menos",
spendLessDescription: "Reduzca el CAC y cierre brechas de valor para mejorar la eficiencia de costos de su organización.",
maximiseROI: "Maximice el ROI",
maximiseROIDescription: "MRR, ARR, CLV, Gestión del ciclo. Comience a mejorar su ROI hoy.",
connectDots: "Conecte los puntos",
connectDotsDescription: "Alinee la automatización, la IA y la estrategia para un marketing SaaS potente.",
empowerGTM: "Potencie el GTM",
empowerGTMDescription: "Maximice su estrategia go-to-market y asegure la financiación de la próxima ronda.",
allOfThese: "Todo esto",
allOfTheseDescription: "Le ayudamos a superar sus mayores desafíos y alcanzar sus métricas más ambiciosas."
,
      OrderForm: "Necesitamos solo un poco de información en este formulario para asegurarnos de conectarte con nuestro mejor experto para tus necesidades."
,
            name: "Nombre",
            location: "Ubicación",
            category: "Categoría",
            starRating: "Calificación de Estrellas",
            review: "Reseña",
            seo: "SEO",
            sem: "SEM",
            crm: "CRM",
            emailMarketing: "Marketing por Correo",
            reviews: {
              review1: "QuickflowAI transformó por completo nuestra estrategia de SEO. Antes estábamos enterrados en las últimas páginas de Google, pero con la ayuda del equipo, ¡ahora estamos en la cima de las búsquedas! ¡Nuestra visibilidad nunca ha sido tan alta!",
              review2: "Invertimos en SEM con QuickflowAI y los resultados fueron fantásticos. Las campañas se optimizaron con precisión y la cantidad de clientes potenciales calificados aumentó exponencialmente. ¡Nuestra tasa de conversión más que se duplicó!",
              review3: "QuickflowAI implementó un sistema CRM robusto que revolucionó nuestro servicio al cliente. Ahora tenemos datos valiosos sobre nuestros clientes y podemos ofrecer una experiencia personalizada que mejora mucho la retención.",
              review4: "Nuestra estrategia de email marketing estaba estancada, pero el equipo de QuickflowAI logró revitalizarla. Ahora nuestras campañas tienen una tasa de apertura y clics mucho más alta, lo que se traduce en más ventas y mayor participación de los clientes."
            },
      didYouKnow: "¿Sabías que:",
      notificationText: "Creamos marcas en las que la gente confía y campañas que los hacen actuar. Hacemos que la gente se preocupe por tu marca usando el poder de la conexión humana.",
      readyToFeel: "¿Estás listo para hacer que la gente sienta algo?",
      yes: "Sí",
      step3Message: "describe lo que necesitas.",
      welcome: "El modelo digital tradicional",
      welcome2: "ya no funciona.",
      RequestaQuote: "Solicitar un Presupuesto",
      NextStep: "Próximo Paso",
      nearLocation: "Creo que podrías estar cerca de las afueras de",
      nearLocation1: "¡Comienza a marcar la diferencia allí!",
      funnel: "Sitio creado con amor e IA en tiempo récord (con pausa para café)",
      formtile: "Redefine Tus Reglas con IA",
      step1Message: "¿Cuál es tu nombre?",
      step2Message: "Completa el formulario",
      nextStep: "PRÓXIMO PASO",
      requestQuote: "SOLICITAR PRESUPUESTO",
      thankYouMessage: "Gracias por solicitar un presupuesto.",
      aiElevate: "Deja que los expertos en IA eleven tu estrategia.",
      aiElevate1: "Conectamos empresas con sus audiencias a través de la creatividad impulsada por datos. Crecimiento y resultados—guiados por números.",
      phoneNumber: "Número de Teléfono",
      observations: "Describe brevemente el proyecto y sus objetivos",
      fullName: "Nombre Completo",
      loadingMessage: "Estoy esperando saber más...",
      successMessage: "Nuestro equipo se pondrá en contacto contigo pronto con nuestra mejor oferta.",
      letsTalk: "¡Hablemos – No Hay Tiempo Que Perder!",
      ourPartnerships: "Nuestras Alianzas",
      achievements: "Lo Que Hemos Logrado",
      achievementsDescription: "Con metodologías impulsadas por IA, logramos resultados diferentes y aceleramos el progreso en todas las etapas.",
      makePeopleSmile: "IA en todos nuestros embudos de marketing.",
      workWithUs: "Trabaja Con Nuestro Increíble Equipo",
      dontWaste: "No pierdas un momento",
      callUs: "Llámanos",
      startProject: "Comienza a escribir tu próximo proyecto",
      weDo: "Con IA",
      worldwide: "Mundialmente",
      paidMedia: "Medios Pagados",
      creative: "Creatividad",
      dataGrowth: "Datos.Crecimiento.",
      whatWeDo: "Lo Que Hacemos",
      withYou: "Contigo",
      partnersBenefit: "Nuestros socios se benefician de un equipo disruptivo, impulsado por IA, de compradores de medios, creativos y científicos de datos que trabajan sin problemas para transformar tanto tus medios pagados como tu búsqueda orgánica. Al romper los límites convencionales, superamos tus objetivos de crecimiento y redefinimos lo que es posible en todos los canales digitales."
    }
  },
  fr: {

    translation: {
      HowWeThink: "Comment pensons-nous ?",
      davidTitle: "David et Goliath",
      davidDescription: "Nous croyons qu'il y a un David en chacun prêt à vaincre des géants. Nous sommes l'entreprise qui valorise tous les projets, quel que soit leur taille."
,      
      ReadytoGrowth:"Prêt à Croître?",
      achievementsSection: {
        title: "Que pouvons-nous accomplir ensemble?",
        by: "de",
        growMore: "Nous augmentons la génération de leads",
        spendLess: "Nous améliorons l'engagement",
        maximiseROI: "Nous optimisons les conversions de plus de"
      },
      
      mixingArt: "MÉLANGEANT L'ART &",
scienceDrive: "LA SCIENCE POUR STIMULER",
performance: "LA PERFORMANCE",
introDescription: "Nous sommes une agence de marketing full funnel axée sur les données, combinant des techniques de performance de pointe et une production créative de premier ordre pour obtenir des résultats concrets."
,
      extraMileTitle: "Certains Font Un Effort Supplémentaire.",
marathonSubtitle: "Nous Courons Un Marathon.",
extraMileDescription: "Pour nous, le suffisant n'est pas suffisant. Nous sommes fiers de créer des travaux numériques exceptionnels qui se démarquent, peu importe jusqu'où nous devons aller, avec une attention au détail inégalée.",

timeEssenceTitle: "Le Temps Est Précieux.",
noWasteSubtitle: "Et Nous Ne Perdrons Pas Le Vôtre.",
timeEssenceDescription: "Notre équipe est formée à la gestion de projet agile et travaille jour et nuit pour respecter les délais. Aucun projet n'a un délai trop serré pour notre agence; nous sommes prêts pour le défi.",

notBoringTitle: "Ça Ne Doit Pas Être Ennuyeux.",
joySubtitle: "Nous Ramenons La Joie.",
notBoringDescription: "Vous n'êtes pas ennuyeux - et nous non plus. Notre objectif est de rendre notre collaboration agréable et sans douleur, en espérant que vous souhaiterez la renouveler encore et encore.",


      whatOurCustomersSay: "Ce que disent nos clients"
      ,footercallUs: "Commencez maintenant",
      footerdontWaste: "Qu'attendez-vous ?",
      
      marketingStrategy: "Élevez votre stratégie de marketing SaaS au niveau supérieur",
growMore: "Croissez davantage",
growMoreDescription: "Optimisez votre matrice de ventes/marketing pour une croissance sans limites.",
spendLess: "Dépensez moins",
spendLessDescription: "Réduisez le CAC et comblez les lacunes de valeur pour améliorer l'efficacité des coûts de votre organisation.",
maximiseROI: "Maximisez le ROI",
maximiseROIDescription: "MRR, ARR, CLV, Gestion du cycle. Commencez à améliorer votre ROI dès aujourd'hui.",
connectDots: "Connectez les points",
connectDotsDescription: "Alignez l'automatisation, l'IA et la stratégie pour un marketing SaaS puissant.",
empowerGTM: "Renforcez le GTM",
empowerGTMDescription: "Maximisez votre stratégie go-to-market et garantissez le financement du prochain tour.",
allOfThese: "Tout cela",
allOfTheseDescription: "Nous vous aidons à surmonter vos plus grands défis et à atteindre vos objectifs les plus ambitieux."
,
      OrderForm: "Nous avons juste besoin de quelques informations dans ce formulaire pour nous assurer de vous mettre en relation avec notre meilleur expert pour vos besoins."
,
            name: "Nom",
            location: "Emplacement",
            category: "Catégorie",
            starRating: "Évaluation des Étoiles",
            review: "Avis",
            seo: "SEO",
            sem: "SEM",
            crm: "CRM",
            emailMarketing: "Marketing par Email",
            reviews: {
              review1: "QuickflowAI a complètement transformé notre stratégie SEO. Avant, nous étions enterrés dans les dernières pages de Google, mais avec l'aide de l'équipe, nous sommes maintenant en tête des recherches. Notre visibilité n'a jamais été aussi élevée !",
              review2: "Nous avons investi dans SEM avec QuickflowAI et les résultats ont été fantastiques ! Les campagnes ont été optimisées avec précision, et le nombre de prospects qualifiés a augmenté de manière exponentielle. Notre taux de conversion a plus que doublé !",
              review3: "QuickflowAI a mis en place un système CRM robuste qui a révolutionné notre service client. Nous avons maintenant des données précieuses sur nos clients et nous pouvons offrir une expérience personnalisée qui améliore grandement la fidélisation.",
              review4: "Notre stratégie d'email marketing était stagnante, mais l'équipe de QuickflowAI a réussi à la revitaliser. Maintenant, nos campagnes ont un taux d'ouverture et de clics beaucoup plus élevé, ce qui se traduit par plus de ventes et un engagement client accru."
            },
      didYouKnow: "Le saviez-vous:",
      notificationText: "Nous créons des marques auxquelles les gens adhèrent et des campagnes qui les incitent à agir. Nous faisons en sorte que les gens se soucient de votre marque grâce au pouvoir de la connexion humaine.",
      readyToFeel: "Êtes-vous prêt à faire ressentir quelque chose aux gens?",
      yes: "Oui",
      step3Message: "décrivez ce dont vous avez besoin.",
      welcome: "Le modèle numérique traditionnel",
      welcome2: "ne fonctionne plus.",
      RequestaQuote: "Demander un devis",
      NextStep: "Étape Suivante",
      nearLocation: "Je pense que vous êtes peut-être près des environs de",
      nearLocation1: "Commencez à faire la différence ici.",
      funnel: "Site créé avec amour et IA en un temps record (avec une pause café)",
      formtile: "Redéfinissez Vos Règles avec l'IA",
      step1Message: "Quel est votre nom?",
      step2Message: "Complétez le formulaire",
      nextStep: "ÉTAPE SUIVANTE",
      requestQuote: "DEMANDER UN DEVIS",
      thankYouMessage: "Merci d'avoir demandé un devis.",
      aiElevate: "Laissez les experts en IA améliorer votre stratégie.",
      aiElevate1: "Nous connectons les entreprises à leurs audiences avec une créativité pilotée par les données. Croissance et résultats—propulsés par les chiffres.",
      phoneNumber: "Numéro de Téléphone",
      observations: "Décrivez brièvement le projet et ses objectifs",
      fullName: "Nom Complet",
      loadingMessage: "J'attends de savoir plus...",
      successMessage: "Notre équipe vous contactera bientôt avec notre meilleure offre.",
      letsTalk: "Parlons-en – Pas de temps à perdre!",
      ourPartnerships: "Nos Partenariats",
      achievements: "Ce Que Nous Avons Réalisé",
      achievementsDescription: " Avec des méthodologies alimentées par l'IA, nous obtenons des résultats différents et accélérons le progrès à chaque étape.",
      makePeopleSmile: "IA dans tous nos entonnoirs de marketing.",
      workWithUs: "Travaillez Avec Notre Équipe Incroyable",
      dontWaste: "Ne perdez pas un instant",
      callUs: "Appelez-nous",
      startProject: "Commencez à écrire votre prochain projet",
      weDo: "Avec IA",
      worldwide: "Mondialement",
      paidMedia: "Médias Payants",
      creative: "Créativité",
      dataGrowth: "Données.Croissance.",
      whatWeDo: "Ce Que Nous Faisons",
      withYou: "Avec Vous",
      partnersBenefit: "Nos partenaires bénéficient d'une équipe perturbatrice, alimentée par l'IA, composée d'acheteurs de médias, de créatifs et de data scientists qui travaillent ensemble pour transformer à la fois vos médias payants et votre recherche organique. En repoussant les limites conventionnelles, nous dépassons vos objectifs de croissance et redéfinissons ce qui est possible sur tous les canaux numériques."
    }
  },
  de: {
    translation: {
      HowWeThink: "Wie denken wir?",
      davidTitle: "David und Goliath",
      davidDescription: "Wir glauben, dass in jedem Menschen ein David steckt, der bereit ist, Riesen zu besiegen. Wir sind das Unternehmen, das alle Projekte schätzt, unabhängig von ihrer Größe."
      ,
      ReadytoGrowth: "Bereit zu Wachsen?",
      achievementsSection: {
        title: "Was können wir gemeinsam erreichen?",
        by: "um",
        growMore: "Wir steigern die Lead-Generierung",
        spendLess: "Wir verbessern das Engagement",
        maximiseROI: "Wir optimieren die Konversionen um mehr als"
      },
      
      mixingArt: "KUNST &",
scienceDrive: "WISSENSCHAFT ZUR FÖRDERUNG",
performance: "DER LEISTUNG",
introDescription: "Wir sind eine datengetriebene Full-Funnel-Marketing-Agentur, die modernste Leistungstechniken und kreative Produktion auf höchstem Niveau kombiniert, um echte Ergebnisse zu erzielen."
,
      extraMileTitle: "Manche Gehen Die Extrameile.",
marathonSubtitle: "Wir Laufen Einen Marathon.",
extraMileDescription: "Gut genug ist für uns nicht genug. Wir sind stolz darauf, außergewöhnliche digitale Arbeiten zu schaffen, die herausstechen, egal wie weit wir dafür gehen müssen, mit unvergleichlicher Liebe zum Detail.",

timeEssenceTitle: "Zeit Ist Kostbar.",
noWasteSubtitle: "Und Wir Verschwenden Ihre Nicht.",
timeEssenceDescription: "Unser Team ist in agiler Projektverwaltung geschult und arbeitet rund um die Uhr, um fristgerecht zu liefern. Kein Projekt hat einen zu engen Zeitplan für unsere Agentur; wir sind bereit für die Herausforderung.",

notBoringTitle: "Es Muss Nicht Langweilig Sein.",
joySubtitle: "Wir Bringen Die Freude Zurück.",
notBoringDescription: "Sie sind nicht langweilig - und wir auch nicht. Unser Ziel ist es, die Zusammenarbeit mit uns zu einer angenehmen und schmerzfreien Erfahrung zu machen, die Sie hoffentlich wiederholen möchten.",


      whatOurCustomersSay: "Was unsere Kunden sagen"
,footercallUs: "Jetzt starten",
footerdontWaste: "Worauf warten Sie?",

marketingStrategy: "Bringen Sie Ihre SaaS-Marketingstrategie auf die nächste Stufe",
growMore: "Wachsen Sie mehr",
growMoreDescription: "Optimieren Sie Ihre Verkaufs-/Marketingmatrix für unbegrenztes Unternehmenswachstum.",
spendLess: "Geben Sie weniger aus",
spendLessDescription: "Reduzieren Sie den CAC und schließen Sie Wertlücken, um die Kosteneffizienz Ihrer Organisation zu verbessern.",
maximiseROI: "Maximieren Sie den ROI",
maximiseROIDescription: "MRR, ARR, CLV, Zyklusmanagement. Verbessern Sie noch heute Ihren ROI.",
connectDots: "Verbinden Sie die Punkte",
connectDotsDescription: "Richten Sie Automatisierung, KI und Strategie aus für ein leistungsstarkes SaaS-Marketing.",
empowerGTM: "Stärken Sie das GTM",
empowerGTMDescription: "Maximieren Sie Ihre Go-to-Market-Strategie und sichern Sie die Finanzierung der nächsten Runde.",
allOfThese: "All dies",
allOfTheseDescription: "Wir helfen Ihnen, Ihre größten Herausforderungen zu meistern und Ihre ehrgeizigsten Ziele zu erreichen."
,
OrderForm: "Wir benötigen nur einige Informationen in diesem Formular, um sicherzustellen, dass wir Sie mit unserem besten Experten für Ihre Bedürfnisse verbinden."
,
      name: "Name",
      location: "Ort",
      category: "Kategorie",
      starRating: "Sternebewertung",
      review: "Bewertung",
      seo: "SEO",
      sem: "SEM",
      crm: "CRM",
      emailMarketing: "E-Mail-Marketing",
      reviews: {
        review1: "QuickflowAI hat unsere SEO-Strategie komplett verändert. Zuvor waren wir auf den letzten Seiten von Google begraben, aber mit Hilfe des Teams stehen wir jetzt ganz oben bei den Suchergebnissen. Unsere Sichtbarkeit war noch nie so hoch!",
        review2: "Wir haben in SEM mit QuickflowAI investiert und die Ergebnisse waren fantastisch! Die Kampagnen wurden präzise optimiert und die Anzahl qualifizierter Leads stieg exponentiell an. Unsere Conversion-Rate hat sich mehr als verdoppelt!",
        review3: "QuickflowAI hat ein robustes CRM-System implementiert, das unseren Kundenservice revolutioniert hat. Jetzt haben wir wertvolle Daten über unsere Kunden und können eine personalisierte Erfahrung bieten, die die Kundenbindung erheblich verbessert.",
        review4: "Unsere E-Mail-Marketing-Strategie war ins Stocken geraten, aber das QuickflowAI-Team konnte sie wiederbeleben. Jetzt haben unsere Kampagnen eine viel höhere Öffnungs- und Klickrate, was sich in mehr Verkäufen und einer größeren Kundenbindung niederschlägt."
      },
      didYouKnow: "Wussten Sie schon:",
      notificationText: "Wir erschaffen Marken, denen Menschen vertrauen, und Kampagnen, die sie zum Handeln bringen. Wir sorgen dafür, dass sich die Menschen um Ihre Marke kümmern, indem wir die Kraft menschlicher Verbindungen nutzen.",
      readyToFeel: "Sind Sie bereit, Menschen etwas fühlen zu lassen?",
      yes: "Ja",
      step3Message: "beschreiben Sie, was Sie brauchen.",
      welcome: "Das traditionelle digitale Modell",
      welcome2: "funktioniert nicht mehr.",
      RequestaQuote: "Ein Angebot anfordern",
      NextStep: "Nächster Schritt",
      nearLocation: "Ich denke, Sie befinden sich in der Nähe von",
      nearLocation1: "Beginnen Sie dort, einen Unterschied zu machen.",
      funnel: "Website mit Liebe und KI in Rekordzeit erstellt (mit einer Kaffeepause)",
      formtile: "Definieren Sie Ihre Regeln mit KI neu",
      step1Message: "Wie heißen Sie?",
      step2Message: "Vervollständigen Sie das Formular",
      nextStep: "NÄCHSTER SCHRITT",
      requestQuote: "ANGEBOT ANFORDERN",
      thankYouMessage: "Vielen Dank, dass Sie ein Angebot angefordert haben.",
      aiElevate: "Lassen Sie KI-Experten Ihre Strategie optimieren.",
      aiElevate1: "FWir verbinden Unternehmen mit ihrem Publikum durch datengesteuerte Kreativität. Wachstum und Ergebnisse—angetrieben von Zahlen.",
      phoneNumber: "Telefonnummer",
      observations: "Beschreiben Sie kurz das Projekt und seine Ziele",
      fullName: "Vollständiger Name",
      loadingMessage: "Ich warte darauf, mehr zu erfahren...",
      successMessage: "Unser Team wird sich bald mit unserem besten Angebot bei Ihnen melden.",
      letsTalk: "Lassen Sie uns sprechen – Keine Zeit zu verlieren!",
      ourPartnerships: "Unsere Partnerschaften",
      achievements: "Was Wir Erreicht Haben",
      achievementsDescription: "Mit KI-gesteuerten Methoden erzielen wir unterschiedliche Ergebnisse und beschleunigen den Fortschritt in allen Phasen.",
      makePeopleSmile: "KI in allen unseren Marketing-Funnels.",
      workWithUs: "Arbeiten Sie mit unserem erstaunlichen Team",
      dontWaste: "Verlieren Sie keine Zeit",
      callUs: "Rufen Sie uns an",
      startProject: "Beginnen Sie mit der Planung Ihres nächsten Projekts",
      weDo: "WIR MACHEN",
      worldwide: "Weltweit",
      paidMedia: "Bezahlte Medien",
      creative: "Kreativität",
      dataGrowth: "Daten.Wachstum.",
      whatWeDo: "Was Wir Machen",
      withYou: "Mit KI",
      partnersBenefit: "Unsere Partner profitieren von einem disruptiven, KI-gestützten Team aus Medieneinkäufern, Kreativen und Datenwissenschaftlern, die nahtlos zusammenarbeiten, um sowohl Ihre bezahlten Medien als auch Ihre organische Suche zu transformieren. Indem wir konventionelle Grenzen durchbrechen, übertreffen wir Ihre Wachstumsziele und definieren neu, was auf allen digitalen Kanälen möglich ist."
    }
  }
  ,it: {
    translation: {
      mixingArt: "Pronto para Crescer?",
      davidTitle: "Davide e Golia",
      davidDescription: "Crediamo che in ognuno ci sia un Davide pronto a sconfiggere i giganti. Siamo l'azienda che valorizza tutti i progetti, indipendentemente dalla loro dimensione."
      ,
      ReadytoGrowth: "Pronto a Crescere?",
      achievementsSection: {
        title: "Cosa possiamo raggiungere insieme?",
        by: "di",
        growMore: "Aumentiamo la generazione di lead",
        spendLess: "Miglioriamo l'engagement",
        maximiseROI: "Ottimizziamo le conversioni di oltre"
      },
      
      mixingArt: "MESCOLANDO ARTE &",
scienceDrive: "SCIENZA PER GUIDARE",
performance: "LA PERFORMANCE",
introDescription: "Siamo un'agenzia di marketing full funnel guidata dai dati, che combina tecniche di performance all'avanguardia e produzione creativa di alta classe per ottenere risultati concreti."
,
      extraMileTitle: "Alcuni Fanno Un Passo In Più.",
marathonSubtitle: "Noi Corriamo Una Maratona.",
extraMileDescription: "Per noi, il semplice 'abbastanza' non è abbastanza. Siamo orgogliosi di creare lavori digitali eccezionali che si distinguono, indipendentemente da quanto lontano dobbiamo andare, con un'attenzione ai dettagli impareggiabile.",

timeEssenceTitle: "Il Tempo È Essenziale.",
noWasteSubtitle: "E Non Sprecheremo Il Vostro.",
timeEssenceDescription: "Il nostro team è formato nella gestione agile dei progetti e lavora incessantemente per rispettare le scadenze. Nessun progetto ha una tempistica troppo stretta per la nostra agenzia; siamo pronti alla sfida.",

notBoringTitle: "Non Deve Essere Noioso.",
joySubtitle: "Ritorniamo Alla Gioia.",
notBoringDescription: "Non siete noiosi - e nemmeno noi. Il nostro obiettivo è rendere la collaborazione con noi un'esperienza piacevole e senza intoppi, che speriamo vogliate ripetere ancora e ancora.",

      footercallUs: "Inizia ora",
footerdontWaste: "Cosa stai aspettando?",

      whatOurCustomersSay: "Cosa dicono i nostri clienti",
      marketingStrategy: "Porta la tua strategia di marketing SaaS al livello successivo",
      growMore: "Cresci di più",
      growMoreDescription: "Ottimizza la tua matrice di vendite/marketing per una crescita aziendale senza limiti.",
      spendLess: "Spendi meno",
      spendLessDescription: "Riduci il CAC e colma le lacune di valore per migliorare l'efficienza dei costi della tua organizzazione.",
      maximiseROI: "Massimizza il ROI",
      maximiseROIDescription: "MRR, ARR, CLV, Gestione del ciclo. Inizia a migliorare il tuo ROI oggi.",
      connectDots: "Collega i punti",
      connectDotsDescription: "Allinea automazione, IA e strategia per un marketing SaaS potente.",
      empowerGTM: "Potenzia il GTM",
      empowerGTMDescription: "Massimizza la tua strategia go-to-market e assicura il finanziamento per il prossimo round.",
      allOfThese: "Tutto questo",
      allOfTheseDescription: "Ti aiutiamo a superare le tue sfide più grandi e a raggiungere i tuoi obiettivi più ambiziosi."
,      
      OrderForm: "Abbiamo bisogno di alcune informazioni in questo modulo per garantire che ti colleghiamo con il nostro miglior esperto per le tue esigenze."
,
      name: "Nome",
      location: "Località",
      category: "Categoria",
      starRating: "Valutazione a Stelle",
      review: "Recensione",
      seo: "SEO",
      sem: "SEM",
      crm: "CRM",
      emailMarketing: "Email Marketing",
      reviews: {
        review1: "QuickflowAI ha completamente trasformato la nostra strategia SEO. Prima eravamo sepolti nelle ultime pagine di Google, ma con l'aiuto del team ora siamo in cima alle ricerche. La nostra visibilità non è mai stata così alta!",
        review2: "Abbiamo investito in SEM con QuickflowAI e i risultati sono stati fantastici! Le campagne sono state ottimizzate con precisione e il numero di lead qualificati è aumentato esponenzialmente. Il nostro tasso di conversione è più che raddoppiato!",
        review3: "QuickflowAI ha implementato un robusto sistema CRM che ha rivoluzionato il nostro servizio clienti. Ora abbiamo dati preziosi sui nostri clienti e possiamo offrire un'esperienza personalizzata che migliora notevolmente la fidelizzazione.",
        review4: "La nostra strategia di email marketing era stagnante, ma il team di QuickflowAI è riuscito a rivitalizzarla. Ora le nostre campagne hanno un tasso di apertura e clic molto più alto, il che si traduce in più vendite e maggiore coinvolgimento dei clienti."
      },
      welcome: "Il modello digitale tradizionale",
      welcome2: "è rotto.",
      nearLocation: "Penso che tu possa essere vicino alla periferia di",
      nearLocation1: "Inizia a fare la differenza lì.",
      funnel: "Sito creato con amore e IA in tempo record (con pausa per un caffè)",
      formtile: "Ridefinisci le tue regole con l'IA",
      step1Message: "Come ti chiami?",
      step2Message: "Completa il modulo",
      RequestaQuote: "Richiedi un preventivo",
      step3Message: "descrivi ciò di cui hai bisogno.",
      nextStep: "PROSSIMO PASSO",
      requestQuote: "RICHIEDI PREVENTIVO",
      thankYouMessage: "Grazie per aver richiesto un preventivo.",
      aiElevate: "Lascia che gli esperti di IA elevino la tua strategia.",
      aiElevate1: "Colleghiamo le aziende al loro pubblico con creatività guidata dai dati. Crescita e risultati—alimentati dai numeri.",
      phoneNumber: "Numero di telefono",
      observations: "Descrivi brevemente il progetto e i suoi obiettivi",
      fullName: "Nome completo",
      loadingMessage: "Sto aspettando di saperne di più...",
      successMessage: "Il nostro team ti contatterà presto con la nostra migliore offerta.",
      letsTalk: "Parliamo – Non c'è tempo da perdere!",
      ourPartnerships: "Le nostre partnership",
      achievements: "Cosa abbiamo raggiunto",
      achievementsDescription: "Con metodologie guidate dall'IA, otteniamo risultati diversi e acceleriamo il progresso in ogni fase.",
      makePeopleSmile: "IA in tutti i nostri funnel di marketing.",
      workWithUs: "Lavora con il nostro fantastico team",
      dontWaste: "Non sprecare un momento",
      callUs: "Chiamaci",
      startProject: "Inizia a scrivere il tuo prossimo progetto",
      weDo: "Con IA",
      worldwide: "In tutto il mondo",
      paidMedia: "Media a pagamento",
      creative: "Creatività",
      dataGrowth: "Dati.Crescita.",
      whatWeDo: "Cosa facciamo",
      withYou: "Con te",
      partnersBenefit: "I nostri partner beneficiano di un team rivoluzionario, alimentato dall'IA, di acquirenti di media, creativi e scienziati dei dati che lavorano in modo coeso per trasformare sia i tuoi media a pagamento che la ricerca organica. Rompendo i confini convenzionali, spingiamo i tuoi obiettivi di crescita oltre le aspettative e ridefiniamo ciò che è possibile in ogni canale digitale.",
      
      // Sezione 1
      paidOrganicMedia: "Media a pagamento e organici",
      mediaDescription: "Implementa una strategia a imbuto completo su Paid Social e Paid Search per costruire consapevolezza e guidare il fatturato.",
      paidSocial: "Social Media a Pagamento",
      paidSearch: "Ricerca a Pagamento",
      seo: "SEO",
      
      // Sezione 2
      creativeDescription: "Crea annunci ad alte prestazioni che costruiscono il marchio e alimentano le tue prestazioni.",
      creativeStrategy: "Strategia Creativa",
      adCreative: "Creatività Pubblicitaria",
      contentShoots: "Riprese di Contenuti",
      ugc: "Contenuto Generato dagli Utenti (UGC)",
      
      // Sezione 3
      dataScience: "Scienza dei dati",
      dataScienceDescription: "Sblocca nuove vie per la crescita con intuizioni abilitate dalla tecnologia che risolvono i tuoi punti ciechi.",
      attributionModelling: "Modellazione dell'attribuzione",
      lifetimeValueAnalysis: "Analisi del valore a vita",
      barkInsights: "Bark.Insights",
      NextStep: "Prossimo Passo",
      didYouKnow: "Lo sapevi:",
      notificationText: "Creiamo marchi in cui le persone credono e campagne che li portano all'azione. Facciamo sì che le persone si interessino al tuo marchio usando il potere della connessione umana.",
      readyToFeel: "Sei pronto a far sentire qualcosa?",
      yes: "Sì"
    }
  }
  
  
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // Idioma padrão
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React já faz o escape por padrão
    },
  });

export default i18n;
