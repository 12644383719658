import React, { useEffect, useRef } from 'react';
import { IoIosInformationCircle } from 'react-icons/io'; // Import the icon
import { TiArrowUpOutline } from "react-icons/ti";
import { FaThumbsUp } from "react-icons/fa";
import { useTranslation } from 'react-i18next'; // Import the translation hook

const NotificationPopUp = () => {
  const { t } = useTranslation(); // Hook para tradução
  const notificationRef = useRef(null); // Ref for the notification element
  const closeBtnRef = useRef(null);     // Ref for the close button
  const conversionBtnRef = useRef(null);// Ref for the conversion button

  useEffect(() => {
    // Function to show the notification on scroll
    const handleScroll = () => {
      if (window.scrollY > 300 && notificationRef.current) {
        notificationRef.current.classList.add("active-unique");
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Function to handle closing the notification
    const handleClose = () => {
      if (notificationRef.current) {
        notificationRef.current.classList.add("hidden");
      }
    };

    // Function to handle conversion button click
    const handleConversionClick = (event) => {
      event.preventDefault(); // Prevent default button behavior
      if (notificationRef.current) {
        notificationRef.current.classList.add("hidden");
      }
      document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' });
    };

    // Attach event listeners
    if (closeBtnRef.current) {
      closeBtnRef.current.addEventListener('click', handleClose);
    }

    if (conversionBtnRef.current) {
      conversionBtnRef.current.addEventListener('click', handleConversionClick);
    }

    // Cleanup events and references when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (closeBtnRef.current) {
        closeBtnRef.current.removeEventListener('click', handleClose);
      }
      if (conversionBtnRef.current) {
        conversionBtnRef.current.removeEventListener('click', handleConversionClick);
      }
    };
  }, []);

  // JSX structure for the notification popup
  return (
    <div id="slideInNotificationUnique" ref={notificationRef} className="slide-in-unique">
      <span className="close-btn-unique" ref={closeBtnRef}>&times;</span>
      <h2 className="flex items-center text-lg lg:text-2xl font-bold">
        <IoIosInformationCircle className="mr-2 text-black" />
        {t('didYouKnow')} {/* Tradução da chave 'didYouKnow' */}
      </h2>

      <p>
        {t('notificationText')} {/* Tradução da chave 'notificationText' */}
        <br />
        {t('readyToFeel')} {/* Tradução da chave 'readyToFeel' */}
      </p>
      <div>
        {/* Você pode adicionar mais conteúdo ou imagens aqui se necessário */}
      </div>
      <button
        id="conversionBtnUnique"
        ref={conversionBtnRef}
        className="conversion-btnbasic flex items-center justify-center"
        style={{ color: "#ffffff", marginTop: "10px" }}
      >
        <FaThumbsUp className="mr-2" />
        {t('yes')} {/* Tradução da chave 'yes' */}
      </button>
    </div>
  );
};

export default NotificationPopUp;
