import React, { useEffect, useRef } from 'react';
import './App.css';
import cat from '../src/assets/cat.png'
import { FaSpinner } from 'react-icons/fa';
import { FaCommentDots } from 'react-icons/fa';
import { FaChartLine, FaDollarSign, FaCoins, FaLightbulb, FaChartPie, FaBrain } from 'react-icons/fa';

import './i18n'; // Importa a configuração do i18n
import { useTranslation } from 'react-i18next';
 
import { FaExclamationTriangle } from 'react-icons/fa';
import backgroundVideo from "../src/assets/matrix.mp4";

import { useLocation } from 'react-router-dom'; // Corrigido: Importação do useLocation

import { FaArrowRight, FaCheck } from 'react-icons/fa';  
  
import david from '../src/assets/david_golias.png';
import logo from '../src/assets/logoquickflowai.png';
import { FaPhoneAlt } from 'react-icons/fa'; 
 
import { FaIdCard } from 'react-icons/fa';  
import CustomerReviews from './CustomerReviews';
import { FaUser } from 'react-icons/fa';  
import { FaEnvelope } from 'react-icons/fa';
 
import arrowcta from '../src/assets/arrow-cta.svg';
import { useState } from 'react';
 
import Footer from './Footer';
import NotificationPopUp from './Notification';
 
import BenefitsGrid from './BenefitsGrid';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flaguk from '../src/assets/uk.png';
import flagpt from '../src/assets/pt.png';

 
function App() {


  
  
  const { t, i18n } = useTranslation(); 
const nomeinp = useRef();
const emailinp = useRef();
const observationinp = useRef();
const zipinp = useRef();
const mobileinp = useRef();
const [formstep, setFromstep] = useState(0);
 
 // New state to hold the geoIp value
 const [geoIp, setGeoIp] = useState('');

 
 function useQuery() {
  return new URLSearchParams(useLocation().search);
}

 
const [isOpen, setIsOpen] = useState(false);

// Função para alternar o estado do dropdown
const toggleDropdown = () => {
  setIsOpen(!isOpen);
};
 
const supportedLanguages = ['pt', 'en', 'es', 'fr', 'it', 'de'];

useEffect(() => {
  // Função para encontrar o idioma mais próximo
  const detectBrowserLanguage = () => {
    const browserLanguages = navigator.languages || [navigator.language];

    // Tenta encontrar o primeiro idioma suportado que corresponda ao idioma do navegador
    const matchedLanguage = browserLanguages
      .map(lang => lang.slice(0, 2).toLowerCase()) // Normaliza para "pt", "en", etc.
      .find(lang => supportedLanguages.includes(lang));

    // Se encontrar um idioma suportado, altera o idioma no i18n, senão usa o padrão (ex: 'en')
    const languageToSet = matchedLanguage || 'en';
    i18n.changeLanguage(languageToSet);
  };

  detectBrowserLanguage();
}, [i18n]);



useEffect(() => {
  // Extrair parâmetros da URL
  const queryParams = new URLSearchParams(window.location.search);
  let language = queryParams.get("language")?.toLowerCase() || "";

  // Se existir `language`, atualiza o idioma
  if (language) {
    i18n.changeLanguage(language);
  }
}, []);  

const [formdata, setFormdata] = useState(
  {
 
    name:'',
email:'',
observation:'',
 
phone:''
  }
);

const marks = [
  
];
function valuetext(value) {
   return `${value}`;
}
const handleSliderChange = (event, newValue) => {
  let oldData={...formdata}
 
  setFormdata(oldData);
};

 
const [showMoreText, setShowMoreText] = useState(false); // State to control visibility

 

const validateform = (e) =>{
  let oldData={...formdata}
  let inp = e.target.name;
  let inpvalue =  e.target.value.slice(0, e.target.maxLength);
  oldData[inp] =inpvalue;
    setFormdata(oldData);
}



const validatezip = (e) => {
  let value = e.target.value.replace(/ /g, "");  
  let inputNumbersOnly = value.replace(/\D/g, "");
  if (inputNumbersOnly.length > 8) {
    inputNumbersOnly = inputNumbersOnly.substr(0, 8);
  }
  const splits = inputNumbersOnly.match(/.{1,4}/g);
   let spacedNumber = "";
  if (splits) {
    spacedNumber = splits.join("-");
  }
  let oldData={...formdata}
  let inp = e.target.name;
  oldData[inp] =spacedNumber;
  if (value.length == 8) {
    mobileinp.current.focus();
  }
    setFormdata(oldData);
};
useEffect(()=>{

 
});
useEffect(() => {
  window.addEventListener('load', handleLoad, { once: true });

  return () => window.removeEventListener('load', handleLoad);
}, []);

const handleLoad = () => {
  sendDataToGoogleSheet({ fromwhere: "load" });
};

const checkempty = (inp) =>{
  if(inp.current.value == '')
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checknumberonly = (inp) =>{
  if(!isNaN(inp.current.value))
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checkemail = (inp) =>{
  const errorMessage = document.getElementById('emaillbl'); 
  var emailregex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if(!emailregex.test(inp.current.value))
    {
      errorMessage.textContent = 'Introduza um endereço de e-mail válido.'; 

      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      errorMessage.textContent = ''; 
      inp.current.classList.remove("shadowinput");
    return true;
    }
}

const checkmobile = (inp,length) =>{
  const errorMessage = document.getElementById('mobilelbl');
  let value = inp.current.value;
  value = value.replace(/\D/g, '');
let iserror = false;
  if (value.length > 3) {
    iserror = false;
    errorMessage.textContent = '';  
  }else if (value.length === 9 && value[0] !== '9') {
    errorMessage.textContent = 'O número de telefone deve começar com 9.';
    iserror = true;
  } else if (value.length < 9) {
    errorMessage.textContent = 'O número de telefone deve ter exatamente 9 dígitos.';
    iserror = true;
  }
  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}


const checkpostalcode = (inp, length) =>{
  const errorMessage = document.getElementById("ziplbl");
  let iserror = false;
  if(inp.current.value.length != length)
    {
      errorMessage.textContent = 'O código postal deve ser exactamente 7 dígitos.';
      iserror = true;
    }else{
      iserror = false;
      errorMessage.textContent = '';
    }

  if(iserror)
    {
      inp.current.style.backgroundColor = "white"
      inp.current.classList.add("shadowinput");
      inp.current.focus();
      return false;
    }else{
      inp.current.classList.remove("shadowinput");
    return true;
    }
}


const checknif = (inp, length) => {
  const errorMessage = document.getElementById("niflbl");
  let iserror = false;
  const nif = inp.current.value;

  // Verifica o comprimento do NIF
  if (nif.length != length) {
    errorMessage.textContent = 'O NIF deve ter exatamente 9 dígitos.';
    iserror = true;
  } else if (!validateNIF(nif)) {
    // Se o comprimento está correto, valida o NIF com as regras adicionais
    errorMessage.textContent = 'O NIF fornecido é inválido.';
    iserror = true;
  } else {
    iserror = false;
    errorMessage.textContent = '';
  }

  if (iserror) {
    inp.current.style.backgroundColor = "white";
    inp.current.classList.add("shadowinput");
    inp.current.focus();
    return false;
  } else {
    inp.current.classList.remove("shadowinput");
    return true;
  }
}

// Função auxiliar para validar o NIF (integrada dentro do checknif)
function validateNIF(nif) {
  // Verifica se o primeiro ou os dois primeiros dígitos são válidos
  if (
    !['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
    !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))
  ) {
    return false;
  }

  // Cálculo do total com base nos primeiros 8 dígitos
  let total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;

  // Calcula o módulo 11
  let modulo11 = total - parseInt(total / 11) * 11;
  let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

  // Verifica se o último dígito corresponde ao comparador
  return nif[8] == comparador;
}


const submitform = (step) => {


 

  
  if (!checkempty(nomeinp) ) {
    setFromstep(1)
    return;
  }
 
 
  if (!checkemail(emailinp) ) {
    setFromstep(1)
    return;
  }
 
  if (step == 1) {
    sendDataToGoogleSheet({
      fromwhere: "step1" ,
      name: nomeinp.current.value,
      email: emailinp.current.value,
    });
    setFromstep(2)
  }
  

  document.getElementById('Txt2Step').innerHTML = 
  nomeinp.current.value.split(" ")[0] + ", " + t('step3Message') ;


 
  
  if(formstep == 2){
if(
  checkmobile(mobileinp,9) == true
)
 {
  sendDataToGoogleSheet({
    fromwhere : "step3",
    name: nomeinp.current.value,
    email: emailinp.current.value,
    phone: mobileinp.current.value,
    observations: observationinp.current.value,
  
  }); 

  
  setFromstep(3)
   

}

  }
}



const getGeoLocation = async () => {
  try {
    const response = await fetch('http://ip-api.com/json/');
    
    if (!response.ok) {
      throw new Error('Failed to fetch IP information');
    }

    const result = await response.json();

    // Verificar se o campo city existe, se não, definir como "Mars"
    const city = result.city || 'Mars';
    if (result && result.country && result.regionName) {
      return `${result.country}, ${result.regionName}, ${city}`;
    } else {
 
    }
  } catch (error) {
    console.error('Erro ao obter geolocalização:', error);
   
  }
};



const getIPAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip; // Retorna o IP público
  } catch (error) {
    console.error('Erro ao obter o IP:', error);
    return 'IP Não Disponível';  
  }
};

 




function getSaudacao() {
  const now = new Date();
  const hour = now.getHours();

  if (hour >= 6 && hour < 13) {
    return "Bom dia";
  } else if (hour >= 13 && hour < 20) {
    return "Boa tarde";
  } else {
    return "Boa noite";
  }}


  
  const sendDataToGoogleSheet = async ({
    fromwhere ="",
    name = "",
    email = "",
    phone = "",
   
    
    observations = "N/A",
  
    publisher = "N/A"
  } = {}) => {
    // URL do Google Apps Script com CORS Anywhere como proxy
    const scriptUrl = 'https://script.google.com/macros/s/AKfycbxw4DRBm9A_raXbIBTOk38d9Y7lz9eK38x95y-D9ku9kPtgdizdkiHXRuDjtJHp8FUz/exec';
    const proxyUrl = 'https://corsproxy.io/?';

    

  
  
    let sessionValue = sessionStorage.getItem('user_session');
    let sessionValueGuid = sessionStorage.getItem('user_sessionGuid');
    
    // Verifica e define `user_session`
    if (!sessionValue) {
      sessionValue = `session_${new Date().getTime()}`;  
      sessionStorage.setItem('user_session', sessionValue);  
    }
    
    // Verifica e define `user_sessionGuid` (GUID)
    if (!sessionValueGuid) {
      sessionValueGuid = crypto.randomUUID(); // Gera um GUID único
      sessionStorage.setItem('user_sessionGuid', sessionValueGuid); // Armazena o GUID no sessionStorage
    }
  
 
    
    const geoIp = await getGeoLocation();
   
    const userIP = await getIPAddress();

    // Definir valores padrão internos
    const data = {
      Campaign: 'GetLeads',
      Session: sessionValue, 
      Date: new Date().toISOString(),  
      IP: userIP || 'N/A', 
      Device: navigator.userAgent.includes('Mobile') ? 'Mobile' : 'Desktop',  
      Geo_IP: geoIp,  
      Name: name, 
      Email: email,  
      Phone: phone,  
  
      Observations: observations,
 
      Publisher: publisher ,
      UUID: sessionValueGuid 
    };
  
    try {
     
      const response = await fetch(proxyUrl + scriptUrl, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.text();
      console.log(`Sucess ${result}`);
    } catch (error) {
      console.error(`Error ${error}`);
    }
  };
  

 
  const saudacao = getSaudacao();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const ProgressCircle = () => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setPercentage((prev) => {
          if (prev >= 66) {
            clearInterval(interval);
            return 66;
          }
          return prev + 1;
        });
      }, 30); // Velocidade do preenchimento

      return () => clearInterval(interval);
    }, []);

    return (
      <div className="progress-circle">
        <div className="circle">
          <div className="mask full">
            <div className="fill" style={{ transform: `rotate(${(percentage / 100) * 360}deg)` }}></div>
          </div>
          <div className="mask half">
            <div className="fill" style={{ transform: `rotate(${(percentage / 100) * 360}deg)` }}></div>
          </div>
          <div className="inside-circle">{percentage}%</div>
        </div>
        <p className="description">Os clientes têm problemas com as leads</p>
      </div>
    );
  };

  
  return (
    

    
    <>


<div  id="askproposal" className="flex flex-col items-center justify-center">

<div className="w-full max-w-[1200px] p-5 pt-10 pb-10 flex items-center justify-between top-0 left-0 z-50 bg-white/95 mx-auto">
  {/* Logo da empresa - Alinhado à esquerda */}
  <img
    src={logo}
    alt="Logo"
    className="max-h-[40px] max-w-[200px] lg:max-w-[300px] md:max-w-[430px]  "
  />

  <div className="ml-auto relative flex flex-col items-end max-w-[200px] md:max-w-none">


    
    {/* Botão para o idioma selecionado */}
    <button
      onClick={toggleDropdown}
      className="text-black text-lg font-bold hover:text-blue-500 transition-colors duration-200 cursor-pointer"
    >
      {i18n.language.toUpperCase()} {/* Exibe o idioma atual */}
    </button>

    {/* Lista suspensa de idiomas (dropdown) */}
    {isOpen && (
      <ul className="absolute top-full right-0 mt-2 w-[120px] bg-white text-black border border-gray-300 rounded-lg shadow-xl z-50">
        {/* Idiomas */}
        <li
          className={`cursor-pointer p-2 hover:bg-blue-100 transition-colors duration-200 ${i18n.language === 'pt' ? 'bg-blue-200 font-bold' : ''}`}
          onClick={() => {
            i18n.changeLanguage('pt');
            setIsOpen(false);
          }}
        >
          PT
        </li>
        <li
          className={`cursor-pointer p-2 hover:bg-blue-100 transition-colors duration-200 ${i18n.language === 'en' ? 'bg-blue-200 font-bold' : ''}`}
          onClick={() => {
            i18n.changeLanguage('en');
            setIsOpen(false);
          }}
        >
          EN
        </li>
        <li
          className={`cursor-pointer p-2 hover:bg-blue-100 transition-colors duration-200 ${i18n.language === 'es' ? 'bg-blue-200 font-bold' : ''}`}
          onClick={() => {
            i18n.changeLanguage('es');
            setIsOpen(false);
          }}
        >
          ES
        </li>
        <li
          className={`cursor-pointer p-2 hover:bg-blue-100 transition-colors duration-200 ${i18n.language === 'fr' ? 'bg-blue-200 font-bold' : ''}`}
          onClick={() => {
            i18n.changeLanguage('fr');
            setIsOpen(false);
          }}
        >
          FR
        </li>
        <li
          className={`cursor-pointer p-2 hover:bg-blue-100 transition-colors duration-200 ${i18n.language === 'it' ? 'bg-blue-200 font-bold' : ''}`}
          onClick={() => {
            i18n.changeLanguage('it');
            setIsOpen(false);
          }}
        >
          IT
        </li>
        <li
          className={`cursor-pointer p-2 hover:bg-blue-100 transition-colors duration-200 ${i18n.language === 'de' ? 'bg-blue-200 font-bold' : ''}`}
          onClick={() => {
            i18n.changeLanguage('de');
            setIsOpen(false);
          }}
        >
          DE
        </li>
      </ul>
    )}
  </div>
</div>
</div>


 



<div
  className="w-full max-w-[1200px] flex flex-col lg:flex-row text-white relative   mx-auto"
 
>
<div className={`relative flex flex-col lg:flex-row w-full z-10 justify-start items-start ${formstep === 0 ? 'block' : 'hidden'}`}>

<div className="text-black p-10">



    {/* Adicionei `text-black` para definir a cor padrão como preta */}

    <p className="font-bebasneuepro text-5xl lg:text-6xl font-bold text-left mt-5r">
  {t('welcome')} <br></br>
  <span style={{ fontSize: "3rem", display: "inline-flex", alignItems: "center" }}>
    <FaExclamationTriangle style={{ color: "#000000", marginRight: "0.5rem" }} />
    {t('welcome2')}
  </span>
</p>

    
    <p
      className="font-bebasneuepro text-2xl lg:text-1xl text-left mt-5 lg:mt-0 pt-5 lg:pt-5"
      style={{ color: "#000000" }} // Modifiquei a cor do texto para preto
      dangerouslySetInnerHTML={{
        __html: t('aiElevate1').replace(/\./g, '.<br />')
      }}
    ></p>

    <br />
    <button
  className="px-4 py-2 border-4 border-[#000000] text-[#000000] bg-[#ffffff] rounded-md font-bold hover:bg-[#FF0064] hover:text-white transition-colors duration-300"
  onClick={() => setFromstep(1)} // Define formstep como 1 ao clicar
>

      {t('RequestaQuote')}
    </button>
  </div>
</div>


 


  
 



 


<div className={`w-full flex flex-col lg:flex-row justify-between mx-4 pt-2 px-4 lg:px-12 mb-3 ${formstep > 0 && formstep < 3 ? 'block' : 'hidden'}`}>
  {/* Informações existentes à esquerda */}
  <div className={`w-full lg:w-1/2 ${formstep > 0 ? 'block' : 'hidden'}`}>
    <div className={`${formstep === 1 ? 'block' : 'hidden'}`}>
      <p className="font-bebasneuepro text-left py-2 uppercase lg:mb-2 text-black" style={{ fontSize: '24px', display: 'flex', alignItems: 'center' }}>
        {t('OrderForm')}
      </p>

      <div className="px-5 relative mb-3">
        <input 
          type="text" 
          ref={nomeinp} 
          name="name" 
          value={formdata.name} 
          onChange={validateform} 
          className="block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal text-black" 
          placeholder={t('step1Message')} 
          maxLength={100} 
        />
        <span className="absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered"></span>
        <FaUser className="absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]" />
      </div>

      <div className="px-5 relative mb-3">
        <input 
          type='text' 
          ref={emailinp} 
          name="email" 
          value={formdata.email} 
          onChange={validateform} 
          className="block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal text-black" 
          placeholder='Email' 
          maxLength={100} 
        />
        <span id="emaillbl" className="absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered"></span>
        <FaEnvelope className="absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]" />
      </div>
    </div>

    <div className={`${formstep === 2 ? 'block' : 'hidden'}`}>
      <p id="Txt2Step" className="font-bebasneuepro font-semibold text-left py-2 uppercase lg:mb-2 text-black" style={{ fontSize: '24px', display: 'flex', alignItems: 'center' }}>
        Complete the form
      </p>

      <div className="flex flex-col lg:flex-row mb-1">
        <div className="w-full px-5 relative mb-3">
          <input 
            type='number' 
            inputMode="numeric" 
            name="phone" 
            ref={mobileinp} 
            value={formdata.phone} 
            onChange={validateform}
            className="block w-full py-4 pl-12 pr-8 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal text-black" 
            placeholder={t('phoneNumber')}  
            maxLength={9} 
          />
          <span id="mobilelbl" className="absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered"></span>
          <FaPhoneAlt className="absolute top-[50%] -translate-y-[50%] left-9 text-black w-[20px] h-[20px]" />
        </div>
      </div>

      <div className="px-5 relative mb-3">
        <textarea 
          ref={observationinp} 
          name="observation" 
          value={formdata.observation} 
          onChange={validateform} 
          className="block w-full py-4 pl-12 pr-8 h-32 rounded-lg border-2 border-[#eaeaea] focus:border-[#ffc0cb] outline-none font-bold placeholder:font-normal resize-none text-black" 
          placeholder={t('observations')}  
          maxLength={500} 
        /> 
        <span id="niflbl" className="absolute top-[1px] left-16 pl-1 text-[0.7em] transition-all duration-10 text-themered"></span>
        <FaIdCard className="absolute top-[15px] left-9 text-black w-[20px] h-[20px]" />
      </div>
    </div>

    {/* Botões de conversão */}
    <div className={`relative w-full flex justify-center items-center ${formstep > 0 && formstep < 3 ? 'block' : 'hidden'}`}>
      <button
        onClick={() => setFromstep(1)}
        className={`absolute left-0 bg-gray-300 hover:bg-gray-400 rounded-full w-[40px] h-[40px] flex justify-center items-center ${formstep === 1 ? 'invisible' : 'visible'}`}
        style={{ marginLeft: '20px' }}
      >
        <img src={arrowcta} alt="Back Arrow" className="w-[10px] rotate-180" />
      </button>

      <button
        id={formstep === 1 ? "FirstStep" : "FinalStep"}
        onClick={() => {
          if (formstep === 1) {
            submitform(1);
          } else if (formstep === 2) {
            submitform(2);
          }
        }}
        className={`flex items-center justify-center w-[230px] ${formstep === 1 ? 'bg-[#28a745] hover:bg-[#86ad5e]' : 'bg-[#FF5722] hover:bg-[#FF7043]'} text-center rounded-md p-2.5 text-white font-bebasneuepro font-semibold text-lg lg:text-2xl focus:outline-none focus:ring-4 ${formstep === 1 ? 'focus:ring-[#86ad5e]' : 'focus:ring-[#FF7043]'} shadow-lg transition-all duration-200`}
        role="button"
        aria-label={formstep === 1 ? 'Go to the next step' : t('requestQuote')}
      >
        {formstep === 1 ? (
          <>
            <FaArrowRight className="mr-2" />
            {t('NextStep')}
          </>
        ) : (
          <>
            <FaCheck className="mr-2" />
            {t('requestQuote')}
          </>
        )}
      </button>
    </div>
  </div>

  {/* Novos blocos à direita */}
  <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="flex flex-col items-start">
        <p className="text-4xl font-bold text-black">1B+</p>
        <div className="w-full h-1 bg-gradient-to-r from-orange-500 to-pink-500 mt-2"></div>
        <p className="mt-2 text-lg font-semibold text-black">Engaged Customers</p>
      </div>
      <div className="flex flex-col items-start">
        <p className="text-4xl font-bold text-black">7x</p>
        <div className="w-full h-1 bg-gradient-to-r from-pink-500 to-red-500 mt-2"></div>
        <p className="mt-2 text-lg font-semibold text-black">Avg ROAS increase</p>
      </div>
      <div className="flex flex-col items-start">
        <p className="text-4xl font-bold text-black">35</p>
        <div className="w-full h-1 bg-gradient-to-r from-pink-500 to-red-500 mt-2"></div>
        <p className="mt-2 text-lg font-semibold text-black">Awards</p>
      </div>
      <div className="flex flex-col items-start">
        <p className="text-4xl font-bold text-black">-42%</p>
        <div className="w-full h-1 bg-gradient-to-r from-orange-500 to-pink-500 mt-2"></div>
        <p className="mt-2 text-lg font-semibold text-black">Avg CAC reduction</p>
      </div>
    </div>
  </div>
</div>


{/* Success Messages for Steps */}
<div className={`mx-auto ${formstep == 3 ? 'block' : 'hidden'} text-black text-left font-bebasneuepro text-2xl lg:text-3xl`}>
  <p className="text-black text-left font-bebasneuepro text-4xl lg:text-5xl mb-3 font-semibold">
    {formdata["name"]}!
  </p>
  <p className="uppercase mb-3 text-black text-left">Thank you for requesting a quote.</p>
  <p className="uppercase mb-3 text-black text-left">Our team will get in touch with you soon with our best offer.</p>
</div>

    



    
  </div>




 
  <div className="text-center py-8">
  <div className="flex justify-center">
  <h2 className="max-w-[1200px] w-[90%] text-3xl font-bold text-gray-800 mb-8">
    {t('marketingStrategy')}
  </h2>
</div>      <div className="max-w-[1200px] w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Card 1 */}
        <div className="border-2 border-gray-300 rounded-lg p-6 shadow-md">
          <div className="text-gray-800 mb-4">
            <FaChartLine className="text-6xl" />
          </div>
          <h3 className="text-2xl font-bold text-gray-900 mb-2 text-left">{t('growMore')}</h3>
          <p className="text-gray-700 text-left">{t('growMoreDescription')}</p>
        </div>

        {/* Card 2 */}
        <div className="border-2 border-gray-300 rounded-lg p-6 shadow-md">
          <div className="text-gray-800 mb-4">
            <FaDollarSign className="text-6xl" />
          </div>
          <h3 className="text-2xl font-bold text-gray-900 mb-2 text-left">{t('spendLess')}</h3>
          <p className="text-gray-700 text-left">{t('spendLessDescription')}</p>
        </div>

        {/* Card 3 */}
        <div className="border-2 border-gray-300 rounded-lg p-6 shadow-md">
          <div className="text-gray-800 mb-4">
            <FaCoins className="text-6xl" />
          </div>
          <h3 className="text-2xl font-bold text-gray-900 mb-2 text-left">{t('maximiseROI')}</h3>
          <p className="text-gray-700 text-left">{t('maximiseROIDescription')}</p>
        </div>

        {/* Card 4 */}
        <div className="border-2 border-gray-300 rounded-lg p-6 shadow-md">
          <div className="text-gray-800 mb-4">
            <FaLightbulb className="text-6xl" />
          </div>
          <h3 className="text-2xl font-bold text-gray-900 mb-2 text-left">{t('connectDots')}</h3>
          <p className="text-gray-700 text-left">{t('connectDotsDescription')}</p>
        </div>

        {/* Card 5 */}
        <div className="border-2 border-gray-300 rounded-lg p-6 shadow-md">
          <div className="text-gray-800 mb-4">
            <FaChartPie className="text-6xl" />
          </div>
          <h3 className="text-2xl font-bold text-gray-900 mb-2 text-left">{t('empowerGTM')}</h3>
          <p className="text-gray-700 text-left">{t('empowerGTMDescription')}</p>
        </div>

        {/* Card 6 */}
        <div className="border-2 border-gray-300 bg-gray-100 rounded-lg p-6 shadow-md">
          <div className="text-gray-800 mb-4">
            <FaBrain className="text-6xl" />
          </div>
          <h3 className="text-2xl font-bold text-gray-900 mb-2 text-left">{t('allOfThese')}</h3>
          <p className="text-gray-700 text-left">{t('allOfTheseDescription')}</p>
        </div>
      </div>
    </div>


    <div className="px-8 py-12 flex justify-center bg-gray-100">
  <div className="flex flex-col md:flex-row items-center justify-center max-w-[1200px] w-[90%] space-y-6 md:space-y-0 md:space-x-10">
    {/* Texto sobre Davi e Golias */}
    <div className="md:w-1/2 text-center md:text-left">
    <p className="text-4xl md:text-5xl font-bold text-gray-800 mb-4">
        {t('davidTitle')}
      </p>
      <p className="text-lg md:text-xl text-gray-600">
        {t('davidDescription')}
      </p>
    </div>

    {/* Imagem do Davi */}
    <div className="md:w-1/2 flex justify-center">
      <img
        src={david}
        alt="Imagem de Davi"
        className="max-h-[400px] "
      />
    </div>
  </div>
</div>


    <div className="bg-black text-white px-8 py-12 flex justify-center">
      <div className="flex flex-col md:flex-row items-center md:items-start justify-between max-w-[1200px] w-[90%]">
        <div className="md:w-1/2 mb-8 md:mb-0 text-5xl md:text-6xl font-bold leading-tight">
          <p>
            {t('mixingArt')}<br />
            {t('scienceDrive')}<br />
            {t('performance')}
          </p>
        </div>
        <div className="md:w-1/2 text-xl md:text-2xl leading-relaxed">
          <p>{t('introDescription')}</p>
        </div>
      </div>
    </div>

 


<div
  className="bg-[#ffffff] w-full text-black text-center p-10 text-2xl font-bold cursor-pointer transition-all duration-300 hover:bg-[#FF0064]  hover:text-black"
  onClick={() => document.getElementById('askproposal').scrollIntoView({ behavior: 'smooth' })}
>
{t('ReadytoGrowth')}
</div>

 
<BenefitsGrid/>
<CustomerReviews/>



<NotificationPopUp />


    <Footer />
    </>
   
  );
}

export default App;